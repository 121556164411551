import React from "react";
import DemoNav from "./DemoNav";
import Template from "./Template";
import { getPatientAge, useDemo, useUnderInvestigation } from "./util";
import { Patient } from "./types";
import routes from "util/routes";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, ColProps, Button } from "react-bootstrap";
import { Card } from "@norah/ui";

interface Props {
  patient: Patient;
}

function EMR({ patient }: Props) {
  const { push } = useHistory();
  const underInvestigation = useUnderInvestigation();
  const { state } = useDemo();
  const { preVisitSelectedOptions } = state;

  function getPatientFeedback() {
    const index = patient.intakeSurvey.findIndex((surveyQuestion) =>
      Boolean(surveyQuestion.freeText)
    );
    if (index > -1 && preVisitSelectedOptions[index]) {
      return (
        <span className="emr-action-recommendations">
          {`"${preVisitSelectedOptions[index]}"`}
        </span>
      );
    }
    return "(None)";
  }

  return (
    <Template
      content={
        <>
          <DemoNav />

          <div className="mb-3">
            <Card
              header={
                <div className="d-flex">
                  <span className="mr-auto">{patient.name}</span>
                  <span>MRN {patient.mrn}</span>
                </div>
              }
            >
              <Container className="emr-data mb-3" fluid={true}>
                <Row>
                  <Description description={patient.gender} label="Gender" />
                  <Description description={patient.dob} label="DOB" />
                  <Description
                    description={getPatientAge(patient)}
                    label="Age"
                  />
                </Row>
                <Row>
                  <Description
                    description={patient.diagnoses.join(", ")}
                    label="Diagnosis"
                  />
                  <Description
                    description={patient.icd10.join(", ")}
                    label="ICD-10"
                  />
                  <Description
                    description={patient.allergies.join(", ")}
                    label="Allergies"
                  />
                </Row>
                <Row>
                  <Description
                    description={patient.immunizations.join(", ")}
                    label="Immunizations"
                  />
                  <Description
                    description={getPatientFeedback()}
                    descriptionProps={{
                      md: 6,
                      xs: 12,
                    }}
                    label="Voice of the Patient"
                    labelProps={{
                      md: 2,
                      xs: 12,
                    }}
                  />
                </Row>
                <Row>
                  <Description
                    description={
                      <ol className="emr-action-recommendations">
                        {(underInvestigation && patient.covidRecommendations
                          ? patient.covidRecommendations
                          : patient.actionRecommendations
                        ).map((action) => (
                          <li key={action}>{action}</li>
                        ))}
                      </ol>
                    }
                    descriptionProps={{
                      md: 10,
                      xs: 12,
                    }}
                    label="Norah Health Actions"
                    labelProps={{
                      md: 2,
                      xs: 12,
                    }}
                  />
                </Row>
              </Container>
            </Card>
          </div>

          <Button
            onClick={() => push(routes.demo.postSurvey.url())}
            variant="primary"
          >
            Continue
          </Button>
        </>
      }
      meta={
        <>
          <p>
            Once Norah has analyzed {patient.name}, the patient record in the
            medical facility's electronic health record system will be updated
            to include targeted Norah action recommendations. These actions are
            to be used by medical personnel to help improve the interactions
            with the patient in order to boost patient satisfaction scores.
          </p>

          <p>
            Since Norah Health actions are determined from various factors -
            including survey results and EHR content - each patient record may
            indicate different recommendations.
          </p>
        </>
      }
      title="EHR Patient Record"
    />
  );
}

export default EMR;

interface DescriptionProps {
  description: React.ReactNode;
  descriptionProps?: ColProps;
  label: React.ReactNode;
  labelProps?: ColProps;
}

function Description({
  description,
  descriptionProps,
  label,
  labelProps,
}: DescriptionProps) {
  return (
    <>
      <Col className="emr-label p-2" md={2} xs={6} {...labelProps}>
        {label}
      </Col>
      <Col className="emr-description p-2" md={2} xs={6} {...descriptionProps}>
        {description}
      </Col>
    </>
  );
}
