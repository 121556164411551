import * as React from "react";
import classnames from "classnames";

export interface CheckboxProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "name" | "type"> {
  containerClassName?: string;
  error?: React.ReactNode;
  label?: React.ReactNode;
  name: string;
  testIdPrefix?: string;
  type?: "checkbox" | "radio";
}

function Checkbox({
  containerClassName,
  error,
  label,
  name,
  testIdPrefix = "",
  type = "checkbox",
  value,
  ...checkboxInputProps
}: CheckboxProps) {
  return (
    <div className={classnames("form-check", containerClassName)}>
      <input
        {...checkboxInputProps}
        className="form-check-input"
        data-testid={`${testIdPrefix}Input`}
        id={`${name}-${value}`}
        name={name}
        type={type}
        value={value}
      />
      {label && (
        <label
          className="form-check-label"
          data-testid={`${testIdPrefix}InputLabel`}
          htmlFor={`${name}-${value}`}
        >
          {label}
        </label>
      )}
      {error && (
        <div
          className="invalid-feedback"
          data-testid={`${testIdPrefix}InputError`}
        >
          {error}
        </div>
      )}
    </div>
  );
}

export default Checkbox;
