import React from "react";
import { useDemoGoBack, useDemoReset } from "./util";
import { Button } from "react-bootstrap";

function DemoNav() {
  const goBack = useDemoGoBack();
  const resetDemo = useDemoReset();
  return (
    <div className="mb-3">
      <Button onClick={goBack} variant="outline-primary">
        Go Back
      </Button>
      <Button className="ml-3" onClick={resetDemo} variant="outline-primary">
        Reset
      </Button>
    </div>
  );
}

export default DemoNav;
