import React from "react";
import TextInput, { TextInputProps } from "./Input";

export interface TextareaProps extends TextInputProps {}

function Textarea(props: TextareaProps) {
  return <TextInput {...props} as="textarea" type={undefined} />;
}

export default Textarea;
