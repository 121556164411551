import React from "react";
import Chart from "components/common/Chart";
import DemoNav from "./DemoNav";
import Template from "./Template";
import { useIsExtraSmall } from "@norah/ui";
import moment from "moment";
import { range, extractScores } from "components/Demo/util";
import routes from "util/routes";
import { useHistory } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";

const patientSatisfaction = (
  width?: number | string | null
): Highcharts.Options => ({
  chart: {
    reflow: true,
    width,
  },
  credits: {
    enabled: false,
  },
  title: {
    text: "Patient Satisfaction",
  },
  subtitle: {
    text: "Past 30 Days",
  },
  series: [
    {
      type: "pie",
      data: [
        {
          name: "1 Star",
          y: 9,
        },
        {
          name: "2 Stars",
          y: 4,
        },
        {
          name: "3 Stars",
          y: 24,
        },
        {
          name: "4 Stars",
          y: 35,
        },
        {
          name: "5 Stars",
          y: 28,
        },
      ],
    },
  ],
});

const providerSatisfactionScores = (
  width?: number | string | null
): Highcharts.Options => ({
  title: {
    text: "Provider Ratings",
  },
  subtitle: {
    text: "Past 12 Months",
  },
  chart: {
    reflow: true,
    type: "bar",
    width,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  xAxis: {
    categories: [
      "Anders, Grayson",
      "Black, Sarah",
      "Drimfield, Sherri",
      "Hashid, Faruk",
      "Johnson, Tom",
      "Toya, Lydia",
    ],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    max: 5,
    title: {
      text: "Provider Satisfaction Scores from Patients",
    },
  },
  legend: {
    layout: "vertical",
    align: "right",
    verticalAlign: "top",
    x: -5,
    y: 100,
    floating: true,
    borderWidth: 1,
    backgroundColor: "#FFFFFF",
    shadow: true,
  },
  series: [
    {
      name: "1 Star",
      type: "column",
      data: [2, 2, 3, 5, 4, 1],
    },
    {
      name: "2 Stars",
      type: "column",
      data: [1, 2, 3, 4, 1, 5],
    },
    {
      name: "3 Stars",
      type: "column",
      data: [5, 1, 4, 3, 2, 4],
    },
    {
      name: "4 Stars",
      type: "column",
      data: [4, 4, 3, 4, 5, 3],
    },
    {
      name: "5 Stars",
      type: "column",
      data: [5, 2, 2, 3, 5, 4],
    },
  ],
});

const providerEngagementScores = (
  width?: number | string | null
): Highcharts.Options => ({
  title: {
    text: "Provider Engagement",
  },
  subtitle: {
    text: "Past 12 Months",
  },
  chart: {
    reflow: true,
    type: "column",
    width,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  xAxis: {
    type: "category",
  },
  yAxis: {
    min: 0,
    max: 10,
    title: {
      text: "Provider Engagement Scores",
    },
  },
  legend: {
    enabled: false,
  },
  series: [
    {
      name: "Providers",
      type: "column",
      colorByPoint: true,
      data: [
        { name: "Anders, Grayson", y: 8 },
        { name: "Black, Sarah", y: 7 },
        { name: "Drimfield, Sherri", y: 9 },
        { name: "Hashid, Faruk", y: 10 },
        { name: "Johnson, Tom", y: 6 },
        { name: "Toya, Lydia", y: 8 },
      ],
    },
  ],
});

const patientSatisfactionOverTime = (
  width?: number | string | null
): Highcharts.Options => ({
  chart: {
    reflow: true,
    width,
  },
  credits: {
    enabled: false,
  },
  title: {
    text: "Patient Satisfaction",
  },
  subtitle: {
    text: "Last 12 Months",
  },
  xAxis: {
    categories: range(12)
      .reverse()
      .map((monthsAgo) =>
        moment()
          .subtract(monthsAgo + 1, "months")
          .format("MMM 'YY")
      ),
  },
  yAxis: {
    title: {
      text: "%",
    },
  },
  series: extractScores(),
});

function Analytics() {
  const { push } = useHistory();
  const isMobile = useIsExtraSmall();
  const width = isMobile ? 340 : null;
  return (
    <Template
      content={
        <>
          <DemoNav />

          <div className="mb-5">
            <Row>
              <Col sm={12} md={6}>
                <Chart options={patientSatisfaction(width)} />
              </Col>
              <Col sm={12} md={6}>
                <Chart options={providerSatisfactionScores(width)} />
              </Col>
            </Row>
          </div>

          <Row className="mb-4">
            <Col sm={12} md={6}>
              <Chart options={providerEngagementScores(width)} />
            </Col>
            <Col sm={12} md={6}>
              <Chart options={patientSatisfactionOverTime(width)} />
            </Col>
          </Row>

          <Button
            onClick={() => push(routes.demo.completed.url())}
            variant="primary"
          >
            Complete
          </Button>
        </>
      }
      meta={
        <>
          <p>
            Norah Health provides visual feedback to medical personnel so you
            know how you are performing. You can easily see:
          </p>

          <ul className="demo-list">
            <li>Action recommendation performance</li>
            <li>Medical facility satisfaction scores</li>
            <li>Medical personnel performance</li>
            <li>and more</li>
          </ul>

          <p>
            This feedback will help point out your strengths and find those
            areas where improvement may be needed. Since accurate feedback is
            crucial to your daily interactions, Norah analytics will be updated
            regularly to help make sure you are providing the best healthcare
            possible.
          </p>
        </>
      }
      title="Analytics"
    />
  );
}

export default Analytics;
