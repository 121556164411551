import React from "react";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";

export interface IconProps extends Omit<FontAwesomeIconProps, "icon"> {
  outline?: boolean;
  testId?: string;
}

export function getFontAwesomeIcon(iconName: IconName, props?: IconProps) {
  const { outline, testId, ...rest } = props || { outline: false };
  if (outline) {
    return (
      <FontAwesomeIcon
        icon={["far", iconName]}
        data-testid={testId}
        {...rest}
      />
    );
  }
  return <FontAwesomeIcon data-testid={testId} icon={iconName} {...rest} />;
}
