import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import { History } from "history";
import LoggedInRoutes from "./LoggedInRoutes";
import NotFoundLayout from "components/Layouts/NotFound";
import routes from "util/routes";
import { trackPage } from "util/gtm";

interface Props {
  history: History;
}

function AppRouter({ history }: Props) {
  React.useEffect(() => {
    trackPage(history.location.pathname);
  }, [history]);

  React.useEffect(
    () =>
      history.listen((location) => {
        trackPage(location.pathname);
      }),
    [history]
  );

  return (
    <Router history={history}>
      <Switch>
        <Route path={routes.home.path}>
          <LoggedInRoutes />
        </Route>
        <Route path={routes.demo.path}>
          <LoggedInRoutes />
        </Route>
        <Route path="*">
          <NotFoundLayout />
        </Route>
      </Switch>
    </Router>
  );
}

export default AppRouter;
