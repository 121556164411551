import React from "react";
import { AlertsContextProps } from "./types";

const defaultContext: AlertsContextProps = {
  alerts: [],
  addAlert: () => {},
};

const AlertsContext = React.createContext(defaultContext);
export default AlertsContext;

export function useAppAlerts() {
  return React.useContext(AlertsContext);
}
