import { getFontAwesomeIcon, IconProps } from "./iconsUtil";

export function AngleDoubleLeftIcon(props: IconProps) {
  return getFontAwesomeIcon("angle-double-left", props);
}
export function AngleDoubleRightIcon(props: IconProps) {
  return getFontAwesomeIcon("angle-double-right", props);
}
export function ArrowLeftIcon(props: IconProps) {
  return getFontAwesomeIcon("arrow-left", props);
}
export function ArrowRightIcon(props: IconProps) {
  return getFontAwesomeIcon("arrow-right", props);
}
export function AngleLeftIcon(props: IconProps) {
  return getFontAwesomeIcon("angle-left", props);
}
export function AngleRightIcon(props: IconProps) {
  return getFontAwesomeIcon("angle-right", props);
}
export function CaretDownIcon(props: IconProps) {
  return getFontAwesomeIcon("caret-down", props);
}
export function CaretUpIcon(props: IconProps) {
  return getFontAwesomeIcon("caret-up", props);
}
export function ChartAreaIcon(props: IconProps) {
  return getFontAwesomeIcon("chart-area", props);
}
export function CheckIcon(props: IconProps) {
  return getFontAwesomeIcon("check", props);
}
export function ChevronLeftIcon(props: IconProps) {
  return getFontAwesomeIcon("chevron-left", props);
}
export function ChevronRightIcon(props: IconProps) {
  return getFontAwesomeIcon("chevron-right", props);
}
export function EllipsisIcon(
  props: IconProps,
  type: "horizontal" | "vertical" = "horizontal"
) {
  return getFontAwesomeIcon(
    type === "horizontal" ? "ellipsis-v" : "ellipsis-h",
    props
  );
}
export function ExclamationIcon(props: IconProps) {
  return getFontAwesomeIcon("exclamation", props);
}
export function ExclamationTriangleIcon(props: IconProps) {
  return getFontAwesomeIcon("exclamation-triangle", props);
}
export function HomeIcon(props: IconProps) {
  return getFontAwesomeIcon("home", props);
}
export function InfoCircleIcon(props: IconProps) {
  return getFontAwesomeIcon("info-circle", props);
}
export function ListIcon(props: IconProps) {
  return getFontAwesomeIcon("list", props);
}
export function MinusIcon(props: IconProps) {
  return getFontAwesomeIcon("minus", props);
}
export function NewspaperIcon(props: IconProps) {
  return getFontAwesomeIcon("newspaper", props);
}
export function PlayCircleIcon(props: IconProps) {
  return getFontAwesomeIcon("play-circle", props);
}
export function PlusIcon(props: IconProps) {
  return getFontAwesomeIcon("plus", props);
}
export function PowerOffIcon(props: IconProps) {
  return getFontAwesomeIcon("power-off", props);
}
export function SearchIcon(props: IconProps) {
  return getFontAwesomeIcon("search", props);
}
export function StarIcon(props: IconProps) {
  return getFontAwesomeIcon("star", props);
}
export function UserCheckIcon(props: IconProps) {
  return getFontAwesomeIcon("user-check", props);
}
export function UserCircleIcon(props: IconProps) {
  return getFontAwesomeIcon("user-circle", props);
}
export function UserFriendsIcon(props: IconProps) {
  return getFontAwesomeIcon("user-friends", props);
}
export function UsersIcon(props: IconProps) {
  return getFontAwesomeIcon("users", props);
}
