export const notEmpty = <TValue>(
  value: TValue | null | undefined
): value is TValue => {
  return value !== null && value !== undefined;
};

export const notEmptyString = <TValue>(
  value: TValue | null | undefined | string
): value is TValue => {
  return value !== null && value !== undefined && value !== "";
};

export const refineStringToEnum = <T extends string>(
  possibleValues: Array<T> | ReadonlyArray<T>,
  value: string
): T | null => {
  const maybe: null | undefined | T = possibleValues.find(
    (v: T) => v === value
  );
  return maybe || null;
};

export const stripEmptyFields = <T extends object>(obj: T): Partial<T> => {
  return Object.keys(obj).reduce((reduction, key) => {
    const objAny = obj as any;
    const val = objAny[key];
    if (val !== null && val !== undefined && val !== "") {
      return {
        ...reduction,
        [key]: val,
      };
    }
    return reduction;
  }, {});
};
