import React from "react";
import { patients } from "./Patients";
import Template from "./Template";
import { getPatientAge } from "./util";
import { useDemo } from "./util";
import routes from "util/routes";
import { Card } from "@norah/ui";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

function DemoStart() {
  const { push } = useHistory();
  const { dispatch } = useDemo();

  return (
    <Template
      content={
        <Row>
          {patients.map((patient, index) => (
            <Col key={index} sm={12} md={6} lg={3}>
              <Card
                className="patient-card"
                imgSrc={patient.img}
                onClick={() => {
                  dispatch({ type: "SELECT_PATIENT", patient });
                  push(routes.demo.initialSurvey.url());
                }}
                title={patient.name}
              >
                <div>
                  {patient.gender}, {getPatientAge(patient)} ({patient.type})
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      }
      meta={
        <>
          <p>Click on one of the patients to get started with the demo.</p>
          <p>
            The following demo outlines the process for how patients and medical
            personnel (e.g., physician, nurse, office worker) interact with the
            Norah product.
          </p>
        </>
      }
      title="Select a Patient"
    />
  );
}

export default DemoStart;
