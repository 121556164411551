import React from "react";
import DemoNav from "./DemoNav";
import { trackEvent } from "util/gtm";
import { Container } from "react-bootstrap";
import { Card } from "@norah/ui";

function CompletedDemo() {
  return (
    <Container fluid={true}>
      <DemoNav />
      <Card className="demo-completed" title="Demo Complete">
        <p>
          {"If you're interested in knowing more, please contact us at "}
          <a
            href="mailto:interested@norahhealth.com"
            onClick={() => trackEvent("mailto:interested")}
          >
            interested@norahhealth.com
          </a>
          .
        </p>
      </Card>
    </Container>
  );
}

export default CompletedDemo;
