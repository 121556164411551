import React from "react";
import DemoNav from "./DemoNav";
import Survey from "./Survey";
import Template from "./Template";
import { Patient } from "./types";
import { useDemo } from "./util";
import routes from "util/routes";
import { useHistory } from "react-router-dom";

interface Props {
  patient: Patient;
}

function PostVisit({ patient }: Props) {
  const { push } = useHistory();
  const { state, dispatch } = useDemo();
  const { postVisitQuestionIndex, postVisitSelectedOptions } = state;

  return (
    <Template
      content={
        <>
          <DemoNav />
          <Survey
            onComplete={() => push(routes.demo.analytics.url())}
            onQuestionChange={(index) =>
              dispatch({
                type: "SET_QUESTION_INDEX",
                index,
                questionType: "postVisitQuestionIndex",
              })
            }
            onSelectOption={(option, index) =>
              dispatch({
                type: "SELECT_SURVEY_OPTION",
                option,
                index,
                optionType: "postVisitSelectedOptions",
              })
            }
            questionIndex={postVisitQuestionIndex}
            selectedSurveyOptions={postVisitSelectedOptions}
            surveyQuestions={patient.postVisitSurvey}
          />
        </>
      }
      meta={
        <>
          <p>
            In order to continually improve upon providing the best action
            recommendations for {patient.name}, a survey will be provided to the
            patient after {patient.gender === "Female" ? " her " : " his "}
            visit with the medical facility.
          </p>

          <p>Patients will be able to provide survey feedback via:</p>
          <ul className="demo-list">
            <li>Text message</li>
            <li>Website (from link emailed or texted)</li>
            <li>In the medical facility</li>
          </ul>
        </>
      }
      title={`Post Visit Survey for ${patient.name}`}
    />
  );
}

export default PostVisit;
