const routes = {
  home: {
    path: "/",
    url: () => "/",
  },
  demo: {
    path: "/p",
    selectPatient: {
      path: "/p/select-patient",
      url: () => "/p/select-patient",
    },
    initialSurvey: {
      path: "/p/initial-survey",
      url: () => "/p/initial-survey",
    },
    investigation: {
      path: "/p/investigation",
      url: () => "/p/investigation",
    },
    postSurvey: {
      path: "/p/post-survey",
      url: () => "/p/post-survey",
    },
    emr: {
      path: "/p/emr",
      url: () => "/p/emr",
    },
    analytics: {
      path: "/p/analytics",
      url: () => "/p/analytics",
    },
    completed: {
      path: "/p/completed",
      url: () => "/p/completed",
    },
  },
};

export default routes;
