import React from "react";
import ReactDOM from "react-dom";
import Router from "components/Router";
import { createBrowserHistory } from "history";
import { buildIcons } from "@norah/icons";

import "@norah/styles";
import "./styles/index.scss";

buildIcons();

const history = createBrowserHistory();
const rootElement = document.getElementById("root");

if (rootElement) {
  ReactDOM.render(<Router history={history} />, rootElement);
}
