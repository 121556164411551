import React from "react";
import moment from "moment";
import DemoNav from "./DemoNav";
import Template from "./Template";
import { Patient } from "./types";
import routes from "util/routes";
import { useHistory } from "react-router-dom";
import { Card } from "@norah/ui";
import { Button, Col, Container, Row, Table } from "react-bootstrap";

interface Props {
  patient: Patient;
}

function UnderInvestigation({ patient }: Props) {
  const { push } = useHistory();
  const reschedule = moment().add(2, "weeks");

  return (
    <Template
      content={
        <>
          <DemoNav />

          <div className="mb-3">
            <Card header={"COVID-19 Patients Under Investigation"}>
              <Container className="mb-3" fluid={true}>
                <Row>
                  <Col className="emr-label p-2">
                    <Table
                      className="puis"
                      striped={true}
                      bordered={true}
                      size="sm"
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Primary Notified</th>
                          <th>Reschedule Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-title="Name">Jayda Pearce</td>
                          <td data-title="Primary">Yes</td>
                          <td data-title="Date">
                            <RescheduleDate date={reschedule} />
                          </td>
                        </tr>
                        <tr>
                          <td data-title="Name">Ritik Olsen</td>
                          <td data-title="Primary"></td>
                          <td data-title="Date"></td>
                        </tr>
                        <tr>
                          <td data-title="Name">Robert Adamson</td>
                          <td data-title="Primary"></td>
                          <td data-title="Date"></td>
                        </tr>
                        <tr className="pui">
                          <td data-title="Name">{patient.name}</td>
                          <td data-title="Primary">Yes</td>
                          <td data-title="Date">
                            <RescheduleDate date={reschedule} />
                          </td>
                        </tr>
                        <tr>
                          <td data-title="Name">Hadley Zhang</td>
                          <td data-title="Primary">Yes</td>
                          <td data-title="Date">
                            <RescheduleDate date={reschedule} />
                          </td>
                        </tr>
                        <tr>
                          <td data-title="Name">Uzma Britt</td>
                          <td data-title="Primary"></td>
                          <td data-title="Date"></td>
                        </tr>
                        <tr>
                          <td data-title="Name">Azeem Murillo</td>
                          <td data-title="Primary">Yes</td>
                          <td data-title="Date">
                            <RescheduleDate date={reschedule} />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Container>
            </Card>
          </div>

          <Button onClick={() => push(routes.demo.emr.url())} variant="primary">
            Continue
          </Button>
        </>
      }
      meta={
        <p>
          When a patient indicates in their pre-visit survey that they may have
          COVID-19 or may have been in contact with someone recently who is
          known to be COVID-19 positive, they will be put into a list of
          patients that will need to be rescheduled in order to help prevent the
          further spread of the disease.
        </p>
      }
      title={"Patient Under Investigation"}
    />
  );
}

export default UnderInvestigation;

interface RescheduleDateProps {
  date: moment.Moment;
}

function RescheduleDate({ date }: RescheduleDateProps) {
  return <>{date.add(1, "days").format("D-MMM-YYYY")}</>;
}
