import { library } from "@fortawesome/fontawesome-svg-core";
import * as Solid from "@fortawesome/free-solid-svg-icons";
import * as Regular from "@fortawesome/free-regular-svg-icons";

export function buildIcons() {
  library.add(
    Regular.faNewspaper,
    Regular.faStar,
    Regular.faUserCircle,
    Solid.faAngleDoubleLeft,
    Solid.faAngleDoubleRight,
    Solid.faAngleLeft,
    Solid.faAngleRight,
    Solid.faArrowLeft,
    Solid.faArrowRight,
    Solid.faCaretDown,
    Solid.faCaretUp,
    Solid.faChartArea,
    Solid.faCheck,
    Solid.faCheckSquare,
    Solid.faChevronLeft,
    Solid.faChevronRight,
    Solid.faEllipsisH,
    Solid.faEllipsisV,
    Solid.faExclamation,
    Solid.faExclamationTriangle,
    Solid.faHome,
    Solid.faInfoCircle,
    Solid.faList,
    Solid.faMinus,
    Solid.faPlayCircle,
    Solid.faPlus,
    Solid.faPowerOff,
    Solid.faSearch,
    Solid.faStar,
    Solid.faUserCheck,
    Solid.faUserCircle,
    Solid.faUserFriends,
    Solid.faUsers
  );
}
