import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "util/routes";
import { useSafeReducer } from "@norah/custom-hooks";
import { DemoAction, DemoState, Patient } from "./types";
import { reducer, useDemo, initialDemoState } from "./util";
import DemoDispatchContext from "./DemoDispatchContext";
import SelectPatient from "./SelectPatient";
import PreVisit from "./PreVisit";
import UnderInvestigation from "./UnderInvestigation";
import EMR from "./EMR";
import PostVisit from "./PostVisit";
import Analytics from "./Analytics";
import CompletedDemo from "./CompletedDemo";
import { NotFound } from "@norah/ui";

function Demo() {
  return (
    <Switch>
      <Route exact={true} path={routes.demo.selectPatient.path}>
        <SelectPatient />
      </Route>

      <Route exact={true} path={routes.demo.initialSurvey.path}>
        <PatientRequired>
          {(patient) => <PreVisit patient={patient} />}
        </PatientRequired>
      </Route>

      <Route exact={true} path={routes.demo.investigation.path}>
        <PatientRequired>
          {(patient) => <UnderInvestigation patient={patient} />}
        </PatientRequired>
      </Route>

      <Route exact={true} path={routes.demo.emr.path}>
        <PatientRequired>
          {(patient) => <EMR patient={patient} />}
        </PatientRequired>
      </Route>

      <Route exact={true} path={routes.demo.postSurvey.path}>
        <PatientRequired>
          {(patient) => <PostVisit patient={patient} />}
        </PatientRequired>
      </Route>

      <Route exact={true} path={routes.demo.analytics.path}>
        <Analytics />
      </Route>

      <Route exact={true} path={routes.demo.completed.path}>
        <CompletedDemo />
      </Route>

      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

function PatientRequired({
  children,
}: {
  children: (patient: Patient) => React.ReactNode;
}) {
  const { state } = useDemo();
  if (!state.patient) {
    return <Redirect to={routes.demo.selectPatient.url()} />;
  }
  return <>{children(state.patient)}</>;
}

function DemoDispatcher() {
  const [state, dispatch] = useSafeReducer(reducer, initialDemoState);
  const value: {
    state: DemoState;
    dispatch: React.Dispatch<DemoAction>;
  } = {
    state,
    dispatch,
  };

  return (
    <DemoDispatchContext.Provider value={value}>
      <Demo />
    </DemoDispatchContext.Provider>
  );
}

export default DemoDispatcher;
