import React from "react";
import moment from "moment";
import { Patient } from "./types";
import { SeriesOptionsType } from "highcharts";
import { DemoState, DemoAction } from "./types";
import DemoDispatchContext from "./DemoDispatchContext";
import routes from "util/routes";
import { trackEvent } from "util/gtm";
import { useHistory } from "react-router-dom";

export const initialDemoState: DemoState = {
  patient: null,
  postVisitQuestionIndex: 0,
  postVisitSelectedOptions: [],
  preVisitQuestionIndex: 0,
  preVisitSelectedOptions: [],
  underInvestigationOptions: [],
};

interface DemoResponse {
  dispatch: React.Dispatch<DemoAction>;
  state: DemoState;
}

export function useDemo(): DemoResponse {
  const demo = React.useContext(DemoDispatchContext);
  return demo;
}

export function useDemoGoBack() {
  const { location, push } = useHistory();
  const underInvestigation = useUnderInvestigation();

  return () => {
    trackEvent("go-back", { category: "demo-action" });

    switch (location.pathname) {
      case routes.demo.investigation.url():
        push(routes.demo.initialSurvey.url());
        break;
      case routes.demo.emr.url():
        if (underInvestigation) {
          push(routes.demo.investigation.url());
        } else {
          push(routes.demo.initialSurvey.url());
        }
        break;
      case routes.demo.postSurvey.url():
        push(routes.demo.emr.url());
        break;
      case routes.demo.analytics.url():
        push(routes.demo.postSurvey.url());
        break;
      case routes.demo.completed.url():
        push(routes.demo.analytics.url());
        break;
      case routes.demo.initialSurvey.url():
      default:
        push(routes.demo.selectPatient.url());
    }
  };
}

export function useDemoReset() {
  const { push } = useHistory();
  const { dispatch } = useDemo();

  return () => {
    dispatch({ type: "RESET" });
    push(routes.demo.selectPatient.url());
  };
}

export function useUnderInvestigation() {
  const { state } = useDemo();
  return state.underInvestigationOptions.some((option) => option);
}

export function reducer(state: DemoState, action: DemoAction): DemoState {
  switch (action.type) {
    case "SELECT_PATIENT":
      if (state.patient && action.patient && action.patient !== state.patient) {
        // patient was previously selected and a new one is being selected
        trackEvent("select-patient", {
          category: "demo-action",
          label: action.patient.name,
        });
        return {
          ...initialDemoState,
          patient: action.patient,
        };
      }

      const patient = action.patient || state.patient;

      trackEvent("select-patient", {
        category: "demo-action",
        label: patient ? patient.name : undefined,
      });

      return {
        ...state,
        patient,
        preVisitSelectedOptions: patient
          ? state.preVisitSelectedOptions.length > 0
            ? // keep the previous state if simply re-selecting the patient
              state.preVisitSelectedOptions
            : // initialize the state if the first time
              [...new Array(patient.intakeSurvey.length)].map(() => null)
          : [],
        postVisitSelectedOptions: patient
          ? state.postVisitSelectedOptions.length > 0
            ? state.postVisitSelectedOptions
            : [...new Array(patient.postVisitSurvey.length)].map(() => null)
          : [],
        underInvestigationOptions: patient
          ? state.underInvestigationOptions.length > 0
            ? state.underInvestigationOptions
            : [...new Array(patient.intakeSurvey.length)].map(() => false)
          : [],
      };

    case "SELECT_SURVEY_OPTION":
      trackEvent("select-survey-option", { category: "demo-action" });
      return {
        ...state,
        [action.optionType]: state[action.optionType].map((option, index) =>
          index === action.index ? action.option : option
        ),
        underInvestigationOptions: state.underInvestigationOptions.map(
          (option, index) =>
            index === action.index ? Boolean(action.investigateCovid) : option
        ),
      };

    case "SET_QUESTION_INDEX":
      trackEvent("set-question-index", { category: "demo-action" });
      return {
        ...state,
        [action.questionType]: action.index,
      };

    case "RESET":
      trackEvent("reset-demo", { category: "demo-action" });
      return initialDemoState;

    default:
      return state;
  }
}

export function getPatientAge(patient: Patient) {
  return moment().diff(moment(patient.dob, "MMM D, YYYY"), "years");
}

export function range(count: number) {
  return [...new Array(count).keys()];
}

export function randomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function extractScores(): SeriesOptionsType[] {
  const allScores: { [score: string]: number[] } = {
    "1 Star": [],
    "2 Stars": [],
    "3 Stars": [],
    "4 Stars": [],
    "5 Stars": [],
  };

  range(12)
    .map(() => generateRandomSatisfactionScores())
    .forEach((s) => {
      allScores["1 Star"].push(s["1 Star"]);
      allScores["2 Stars"].push(s["2 Stars"]);
      allScores["3 Stars"].push(s["3 Stars"]);
      allScores["4 Stars"].push(s["4 Stars"]);
      allScores["5 Stars"].push(s["5 Stars"]);
    });

  return Object.keys(allScores).map((score) => ({
    name: score,
    type: "line",
    data: allScores[score],
  }));
}

// https://codetheory.in/weighted-biased-random-number-generation-with-javascript-based-on-probability/
function generateWeightedList(list: Array<any>, weight: Array<any>) {
  const weightedList: Array<any> = [];

  for (let i = 0; i < weight.length; i++) {
    const multiples = weight[i] * 100;

    for (let j = 0; j < multiples; j++) {
      weightedList.push(list[i]);
    }
  }

  return weightedList;
}

function generateRandomSatisfactionScores(max: number = 100) {
  const list = ["1 Star", "2 Stars", "3 Stars", "4 Stars", "5 Stars"];
  const weight = [0.12, 0.08, 0.2, 0.3, 0.3];
  const weightedList = generateWeightedList(list, weight);
  const scores: { [score: string]: number } = {
    "1 Star": 0,
    "2 Stars": 0,
    "3 Stars": 0,
    "4 Stars": 0,
    "5 Stars": 0,
  };

  for (let i = 0; i < max; i++) {
    ++scores[weightedList[randomInt(0, weightedList.length - 1)]];
  }

  return scores;
}
