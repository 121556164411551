import { Patient } from "./types";
import claire from "../../img/claire.jpg";
import margaret from "../../img/margaret.jpg";
import jason from "../../img/jason.jpg";
import vihaan from "../../img/vihaan.jpg";

export const patientA: Patient = {
  type: "Standard",
  mrn: "603487194",
  firstName: "Claire",
  name: "Claire Johns",
  dob: "May 7, 1995",
  gender: "Female",
  icd10: ["R03.0"],
  diagnoses: ["Elevated Blood Pressure"],
  allergies: ["None"],
  immunizations: ["Flu", "Hepatitis B"],
  img: claire,
  intakeSurvey: [
    {
      question:
        "When you go on vacation, do you like to have the entire time planned before you leave?",
      options: ["Yes", "No"],
    },
    {
      question: "Do you make friends at a new job quickly?",
      options: ["Yes", "No"],
    },
    {
      question: "Do you feel like your brain is always thinking of new ideas?",
      options: ["Yes", "No"],
    },
    {
      question:
        "Is there any information you would like to share with your healthcare providers?",
      freeText: true,
    },
  ],
  postVisitSurvey: [
    {
      question: "Do you feel your providers connected with you?",
      options: ["Yes", "No"],
    },
    {
      question: "Did your providers make you feel comfortable?",
      options: ["Yes", "No"],
    },
    {
      question:
        "Will you pick ABC Medical Clinic again for your healthcare needs?",
      options: ["Yes", "No"],
    },
  ],
  actionRecommendations: [
    "Introduce yourself to the patient and let them tell you something about whatever is interesting to them.",
    "Keep it light, joke around. Tell the patient something that you or your institution is proud of.",
    "Tell the patient that you enjoyed their company and ask them if they have any questions.",
  ],
};

export const patientB: Patient = {
  type: "Standard",
  mrn: "239673290",
  firstName: "Margaret",
  name: "Margaret Samson",
  dob: "Dec 10, 1952",
  gender: "Female",
  icd10: ["C50.919"],
  diagnoses: ["Breast Cancer"],
  allergies: ["Penicillin", "latex"],
  immunizations: ["Flu", "DTaP", "Measles"],
  img: margaret,
  intakeSurvey: [
    {
      question:
        "When you go on vacation, do you like to have the entire time planned before you leave?",
      options: ["Yes", "No"],
    },
    {
      question:
        "Is it true that you get more energy from hanging around other people, than being by yourself?",
      options: ["Yes", "No"],
    },
    {
      question: "Do you often find yourself daydreaming about the future?",
      options: ["Yes", "No"],
    },
    {
      question:
        "Is there any information you would like to share with your healthcare providers?",
      freeText: true,
    },
  ],
  postVisitSurvey: [
    {
      question: "Did the provider show interest in you as a patient?",
      options: ["Yes", "No"],
    },
    {
      question:
        "Did you feel that the provider had enthusiasm throughout the appointment?",
      options: ["Yes", "No"],
    },
    {
      question: "Were you educated on your follow up care?",
      options: ["Yes", "No"],
    },
  ],
  actionRecommendations: [
    "Greet the patient and make small talk about any trip or event that they attended.",
    "Tell the patient the steps you are taking to complete the encounter and ask if they have any questions. Discuss the exciting parts of the encounter.",
    "Give the patient clear and concise instructions about the next steps after this encounter.",
  ],
};

export const patientC: Patient = {
  type: "COVID-19",
  mrn: "295037754",
  firstName: "Jason",
  name: "Jason Groening",
  dob: "May 7, 1981",
  gender: "Male",
  icd10: ["E11.9"],
  diagnoses: ["Type 2 diabetes mellitus without complications"],
  allergies: ["Penicillin"],
  immunizations: [
    "Flu",
    "Smallpox vaccine",
    "Bacille Calmette-Guérin (BCG) vaccine",
  ],
  img: jason,
  intakeSurvey: [
    {
      question:
        "In the past two weeks, have you been in contact with someone known to have COVID-19?",
      options: ["Yes", "No"],
      investigateCovid: "Yes",
    },
    {
      question:
        "Do you currently have fever, shortness of breath, loss of taste or smell, sore throat, or muscle aches?",
      options: ["Yes", "No"],
      investigateCovid: "Yes",
    },
    {
      question:
        "Is there any information you would like to share with your healthcare providers?",
      freeText: true,
    },
  ],
  postVisitSurvey: [
    {
      question: "Do you feel your providers made good use of your time?",
      options: ["Yes", "No"],
    },
    {
      question: "Did your providers address all your concerns?",
      options: ["Yes", "No"],
    },
    {
      question:
        "Were you satisfied with the way your providers addressed any follow up care required?",
      options: ["Yes", "No"],
    },
  ],
  actionRecommendations: [
    "Give the patient a quick description of the service you are performing today.",
    "Give the patient a brief description of the steps to complete the encounter and ask them if they have any questions.",
    "Tell the patient what needs to happen to close the encounter and make it as easy as possible for them to complete their follow up care.",
  ],
  covidRecommendations: [
    "Tell the patient all the protocols your team is following to slow the spread of COVID-19.",
    "Discuss next steps with the patient to make sure they are comfortable coming back for their follow up care.",
    "Ask the patient if they have any safety concerns they would like to further discuss.",
  ],
};

export const patientD: Patient = {
  type: "Standard",
  mrn: "692328661",
  firstName: "Vihaan",
  name: "Vihaan Laghari",
  dob: "Apr 17, 1951",
  gender: "Male",
  icd10: ["Z85.46"],
  diagnoses: [
    "Personal history of malignant neoplasm of prostate",
    "Prostate Cancer",
  ],
  allergies: ["latex"],
  immunizations: ["Flu"],
  img: vihaan,
  intakeSurvey: [
    {
      question:
        "Do you respond to emails or voicemails as soon as you get them?",
      options: ["Yes", "No"],
    },
    {
      question: "You would rather lose an argument than upset a friend?",
      options: ["Yes", "No"],
    },
    {
      question: "Do you find it easy to introduce yourself to other people?",
      options: ["Yes", "No"],
    },
    {
      question:
        "You focus on the concrete information, rather than the what ifs?",
      options: ["Yes", "No"],
    },
    {
      question:
        "Is there any information you would like to share with your healthcare providers?",
      freeText: true,
    },
  ],
  postVisitSurvey: [
    {
      question:
        "Did you feel that the provider had a good connection with you from the begining?",
      options: ["Yes", "No"],
    },
    {
      question:
        "Were you informed on the specific steps during your encounter?",
      options: ["Yes", "No"],
    },
    {
      question:
        "Did you feel that the institution appreciated you for choosing them?",
      options: ["Yes", "No"],
    },
  ],
  actionRecommendations: [
    "Introduce yourself and tell the patient something interesting about your department or institution.",
    "Tell the patient the specifics of the steps you are taking to complete their procedure and allow them to talk as their interests wonder.",
    "Thank them for coming in and wish them a good day.",
  ],
};

export const patients: Patient[] = [patientA, patientB, patientC, patientD];
