import React from "react";
import cn from "classnames";
import { notEmpty } from "@norah/common-utils";

export type MessageItem = {
  whos: "yours" | "mine";
  message: React.ReactNode;
};

type Props = {
  items: (MessageItem | undefined)[];
};

function Messages({ items }: Props) {
  return (
    <div className="message-bubbles">
      {items.filter(notEmpty).map((item, index) => {
        if (item.whos === "yours") {
          return <YourMessages key={index}>{item.message}</YourMessages>;
        }
        return <MyMessages key={index}>{item.message}</MyMessages>;
      })}
    </div>
  );
}

export default Messages;

type MessageProps = {
  children: React.ReactNode;
  className?: string;
  last?: boolean;
};

export function Message({ children, className, last }: MessageProps) {
  return <div className={cn("message", className, { last })}>{children}</div>;
}

export function YourMessages({ children }: { children: React.ReactNode }) {
  return <div className="yours messages">{children}</div>;
}

export function MyMessages({ children }: { children: React.ReactNode }) {
  return <div className="mine messages">{children}</div>;
}
