import React from "react";

interface Props extends React.AnchorHTMLAttributes<any> {
  children: React.ReactNode;
}

function ExternalLink({ children, ...rest }: Props) {
  return (
    <a target="_blank" {...rest}>
      {children}
    </a>
  );
}

export default ExternalLink;
