import React from "react";
import Device from "../common/Device";
import Messages, { MessageItem } from "../common/Messages";

interface Props {
  messages: (MessageItem | undefined)[];
}

function DemoDevice({ messages }: Props) {
  return (
    <div className="demo-device">
      <Device color="black" glare={true} type="iphone-5">
        <div className="demo-screen-content">
          <Messages items={messages} />
        </div>
      </Device>
    </div>
  );
}

export default DemoDevice;
