import React, {
  Dispatch,
  Reducer,
  ReducerAction,
  ReducerState,
  SetStateAction,
} from "react";

export const useInterval = (callback: () => unknown, delay: number | null) => {
  const savedCallback = React.useRef(() => {});

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = window.setInterval(tick, delay);
      return () => window.clearInterval(id);
    }

    return;
  }, [delay]);
};

export const useSetTimeout = (
  callback: () => unknown,
  delay?: number | null
) => {
  const savedCallback = React.useRef(() => {});

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    if (typeof delay === "number") {
      const timer = window.setTimeout(savedCallback.current, delay);
      return () => window.clearTimeout(timer);
    }
    return;
  }, [delay]);
};

export const useRefMounted = () => {
  const mounted = React.useRef<boolean>(true);

  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return mounted;
};

export function usePrevious(value: any) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useSafeState = <T>(
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>] => {
  const mounted = useRefMounted();
  const [value, _setValue] = React.useState<T>(defaultValue);
  const setValue = (newValue: SetStateAction<T>) =>
    mounted.current && _setValue(newValue);
  return [value, setValue];
};

let uniqueId = 0;
const getUniqueId = (prefix: string) => `${prefix}_${uniqueId++}`;

export function useUniqueId(prefix: string = "uid") {
  const idRef = React.useRef(getUniqueId(prefix));
  return idRef.current;
}

export const useSafeReducer = <R extends Reducer<any, any>, I>(
  reducer: R,
  initializerArg: I & ReducerState<R>,
  initializer?: (arg: I & ReducerState<R>) => ReducerState<R>
): [ReducerState<R>, Dispatch<ReducerAction<R>>] => {
  const mounted = useRefMounted();
  const [state, _dispatch] = React.useReducer(
    reducer,
    initializerArg,
    initializer || (undefined as any)
  );
  const dispatch = (action: ReducerAction<R>) =>
    mounted.current && _dispatch(action);
  return [state, dispatch];
};

interface UsePollingParams {
  initialPollCount?: number;
  onPoll: (pollIteration: number) => void;
  pollTimeout?: number;
  shouldPoll: () => boolean;
}

export function usePolling({
  initialPollCount = 10,
  onPoll,
  pollTimeout = 2000,
  shouldPoll,
}: UsePollingParams): [boolean, number] {
  const [pollIteration, setPollIteration] =
    React.useState<number>(initialPollCount);
  const [polling, setPolling] = React.useState<boolean>(
    pollIteration > 0 && shouldPoll()
  );

  React.useEffect(() => {
    if (!shouldPoll()) {
      return;
    }

    if (pollIteration > 0) {
      setPolling(true);
      const timeoutId = setTimeout(() => {
        setPollIteration((iteration) => iteration - 1);
        onPoll(pollIteration);
      }, pollTimeout);

      return () => {
        setPolling(false);
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }
    return;
  }, [onPoll, pollIteration, pollTimeout, setPolling, shouldPoll]);

  return [polling, pollIteration];
}
