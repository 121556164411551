import React from "react";
import { scrollToTop } from "util/dom";
import { Container, Row, Col } from "react-bootstrap";
import { Card, useIsExtraSmall } from "@norah/ui";

interface Props {
  content: React.ReactNode;
  meta: React.ReactNode;
  title: React.ReactNode;
}

function DemoTemplate({ content, meta, title }: Props) {
  const isMobile = useIsExtraSmall();

  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <Container className="mb-4" fluid={true}>
      <Row>
        <Col className={isMobile ? "order-2" : "order-1"} sm={9}>
          {content}
        </Col>
        <Col className={isMobile ? "order-1" : "order-2"} sm={3}>
          <Card className="demo-meta-info" title={title}>
            {meta}
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default DemoTemplate;
