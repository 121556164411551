import React from "react";
import ReactDOM from "react-dom";

const portalRootId = "modal-root";
let portalRoot = document.getElementById(portalRootId);

interface PortalProps {
  children: React.ReactNode;
}

export function Portal({ children }: PortalProps) {
  const target = usePortal();
  return ReactDOM.createPortal(children, target);
}

export function usePortal() {
  const targetRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (!portalRoot) {
      portalRoot = document.createElement("div");
      portalRoot.setAttribute("id", portalRootId);
      document.body.appendChild(portalRoot);
    }
    const target = targetRef.current;
    if (portalRoot && target) {
      portalRoot.appendChild(target);
      return () => {
        target.remove();
      };
    }
    return;
  }, []);

  function getTargetElement() {
    // lazily initialize
    if (!targetRef.current) {
      targetRef.current = document.createElement("div");
    }
    return targetRef.current;
  }

  return getTargetElement();
}
