import React from "react";
import { useSafeState } from "@norah/custom-hooks";
import DemoDevice from "./DemoDevice";
import { SurveyQuestion } from "./types";
import { Message } from "components/common/Messages";
import { Row, Col, Button, ProgressBar } from "react-bootstrap";
import { Card } from "@norah/ui";
import { Checkbox, Textarea } from "@norah/forms";

interface Props {
  initialMessage?: React.ReactNode;
  onComplete: () => unknown;
  onQuestionChange: (currentQuestionIndex: number) => unknown;
  onSelectOption: (
    option: string,
    index: number,
    investigateCovid?: boolean
  ) => unknown;
  questionIndex: number;
  selectedSurveyOptions: (string | null)[];
  surveyQuestions: SurveyQuestion[];
}

function Survey({
  initialMessage,
  onComplete,
  onQuestionChange,
  onSelectOption,
  questionIndex: _questionIndex,
  selectedSurveyOptions,
  surveyQuestions,
}: Props) {
  const content = React.useRef<Element | null>();
  const [questionIndex, setQuestionIndex] = useSafeState<number>(
    _questionIndex || 0
  );
  const [selectedOptions, setSelectedOptions] = useSafeState<(string | null)[]>(
    selectedSurveyOptions.length > 0
      ? selectedSurveyOptions
      : [...new Array(surveyQuestions.length)].map(() => null)
  );

  const highestIndex = surveyQuestions.length - 1;
  const surveyQuestion = surveyQuestions[questionIndex];
  const selectedOption = selectedOptions[questionIndex];
  const selectedCount = selectedOptions.filter(Boolean).length;

  const progress = Math.round(
    (Math.max(questionIndex, selectedCount) / surveyQuestions.length) * 100
  );

  let requiredFreeText: boolean = !surveyQuestion.freeText;

  if (typeof surveyQuestion.freeText === "object") {
    requiredFreeText = Boolean(surveyQuestion.freeText.required);
  }

  function scrollDown() {
    setTimeout(() => {
      if (content.current) {
        content.current.scrollTop = content.current.scrollHeight;
      }
    }, 10);
  }

  React.useEffect(() => {
    content.current = document.querySelector(".demo-screen-content");
    if (selectedOption) {
      scrollDown();
    }
  }, [selectedOption]);

  return (
    <>
      <div className="mb-3">
        <ProgressBar now={progress} />
      </div>

      <Card title={surveyQuestion.question}>
        <Row>
          <Col className="mb-3">
            {surveyQuestion.options && (
              <div className="mb-3">
                {surveyQuestion.options.map((option) => (
                  <Checkbox
                    containerClassName="mb-2"
                    checked={selectedOption === option}
                    key={option}
                    label={option}
                    name={`surveyOption-${questionIndex}`}
                    onChange={(e) => {
                      const value = e.currentTarget.value;
                      onSelectOption(
                        value,
                        questionIndex,
                        surveyQuestion.investigateCovid === value
                      );
                      setSelectedOptions((selected) =>
                        selected.map((anOption, index) =>
                          index === questionIndex ? value : anOption
                        )
                      );
                      scrollDown();
                    }}
                    type="radio"
                    value={option}
                  />
                ))}
              </div>
            )}

            {surveyQuestion.freeText && (
              <div className="mb-3">
                <Textarea
                  name={`surveyQuestion-${questionIndex}`}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    onSelectOption(value, questionIndex);
                    setSelectedOptions((selected) =>
                      selected.map((anOption, index) =>
                        index === questionIndex ? value : anOption
                      )
                    );
                    scrollDown();
                  }}
                  rows={4}
                  value={selectedOption || ""}
                />
              </div>
            )}

            <Button
              disabled={questionIndex === 0}
              onClick={() => {
                setQuestionIndex((index) => index - 1);
                onQuestionChange(Math.max(0, questionIndex - 1));
              }}
              variant="outline-primary"
            >
              &#8592; Previous
            </Button>

            {questionIndex < highestIndex && (
              <Button
                className="ml-3"
                disabled={!selectedOption}
                onClick={() => {
                  setQuestionIndex((index) => index + 1);
                  onQuestionChange(Math.min(highestIndex, questionIndex + 1));
                }}
                variant="outline-primary"
              >
                Next &#8594;
              </Button>
            )}

            {questionIndex === highestIndex && (
              <Button
                className="ml-3"
                disabled={!selectedOption && requiredFreeText}
                onClick={onComplete}
                variant="primary"
              >
                Complete
              </Button>
            )}
          </Col>

          <Col>
            <DemoDevice
              messages={[
                initialMessage && questionIndex === 0
                  ? {
                      whos: "yours",
                      message: <Message>{initialMessage}</Message>,
                    }
                  : undefined,
                {
                  whos: "yours",
                  message: (
                    <Message last={true}>{surveyQuestion.question}</Message>
                  ),
                },
                selectedOption
                  ? {
                      whos: "mine",
                      message: <Message last={true}>{selectedOption}</Message>,
                    }
                  : undefined,
              ]}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default Survey;
