import React from "react";
import { toast } from "react-toastify";
import AlertsContext from "./Context";
import { Alert as AlertType } from "./types";
import {
  CheckIcon,
  ExclamationTriangleIcon,
  InfoCircleIcon,
} from "@norah/icons";

function Alerts() {
  const { alerts } = React.useContext(AlertsContext);

  React.useEffect(() => {
    alerts.map((alert) => {
      switch (alert.type) {
        case "danger":
          return toast.error(
            <Alert
              alert={alert}
              icon={<ExclamationTriangleIcon />}
              title="Error"
            />,
            {
              autoClose: 20000,
              // using the message as the toastId allows uniqueness
              toastId: alert.message,
            }
          );
        case "success":
          return toast.success(
            <Alert alert={alert} icon={<CheckIcon />} title="Success" />,
            {
              autoClose: 6000,
              toastId: alert.message,
            }
          );
        case "info":
          return toast.info(
            <Alert alert={alert} icon={<InfoCircleIcon />} title="Info" />,
            {
              autoClose: 10000,
              toastId: alert.message,
            }
          );
        case "warning":
          return toast.warn(
            <Alert
              alert={alert}
              icon={<ExclamationTriangleIcon />}
              title="Warning"
            />,
            {
              autoClose: 15000,
              toastId: alert.message,
            }
          );
        default:
          return toast(<Alert alert={alert} />, {
            autoClose: 6000,
            toastId: alert.message,
          });
      }
    });
  }, [alerts]);

  return null;
}

export default Alerts;

interface AlertProps {
  alert: AlertType;
  icon?: React.ReactNode;
  title?: React.ReactNode;
}

function Alert({ alert, icon, title }: AlertProps) {
  return (
    <>
      {title && (
        <div className="h5">
          {icon && <span className="mr-2">{icon}</span>}
          {title}
        </div>
      )}
      {alert.message}
    </>
  );
}

interface CloseToastButtonProps {
  // `closeToast` is provided by `react-toastify`
  closeToast?: () => void;
}

function CloseToastButton({ closeToast }: CloseToastButtonProps) {
  return (
    <button
      aria-label="Close"
      className="close"
      data-dismiss="alert"
      onClick={closeToast}
      type="button"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  );
}

export function configureAlerts() {
  toast.configure({
    closeButton: <CloseToastButton />,
    closeOnClick: false,
    draggable: false,
    hideProgressBar: true,
    pauseOnHover: true,
    position: "top-right",
  });
}
