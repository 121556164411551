import React from "react";
import ExternalLink from "../ExternalLink";

interface Props {
  additionalContent?: React.ReactNode;
}

function Footer({ additionalContent }: Props) {
  return (
    <footer className="c-footer app-footer">
      &copy; {new Date().getFullYear()} Norah Health |
      <ExternalLink className="ml-1 mr-1" href="https://norahhealth.com">
        norahhealth.com
      </ExternalLink>
      {additionalContent}
    </footer>
  );
}

export default Footer;
