import React from "react";
import { Form, FormControlProps, InputGroup } from "react-bootstrap";

export interface TextInputProps extends FormControlProps {
  as?: "textarea";
  autoComplete?: string;
  autoFocus?: boolean;
  error?: React.ReactNode;
  inputRef?: React.RefObject<any>;
  label?: React.ReactNode;
  maxLength?: number;
  name: string;
  onClick?: () => void;
  onKeyUp?: React.KeyboardEventHandler;
  placeholder?: string;
  renderAppend?: () => React.ReactNode;
  rows?: number; // for textarea
  showError?: boolean;
  style?: React.CSSProperties;
  testIdPrefix?: string;
}

function TextInput({
  error,
  inputRef,
  label,
  name,
  renderAppend,
  showError = true,
  testIdPrefix = "",
  ...formControlProps
}: TextInputProps) {
  let renderedInput = (
    <Form.Control
      ref={inputRef}
      {...formControlProps}
      data-testid={`${testIdPrefix}Input`}
    />
  );

  if (renderAppend) {
    renderedInput = (
      <InputGroup>
        {renderedInput}
        <InputGroup.Append data-testid={`${testIdPrefix}InputAppend`}>
          {renderAppend()}
        </InputGroup.Append>
      </InputGroup>
    );
  }

  return (
    <Form.Group controlId={name} data-testid={`${testIdPrefix}InputGroup`}>
      {label && (
        <Form.Label data-testid={`${testIdPrefix}InputLabel`}>
          {label}
        </Form.Label>
      )}
      {renderedInput}
      {showError && error && (
        <Form.Text
          className="text-danger"
          data-testid={`${testIdPrefix}InputError`}
        >
          {error}
        </Form.Text>
      )}
    </Form.Group>
  );
}

export default TextInput;
