import React from "react";

function NotFound() {
  return (
    <div className="h-50 d-flex justify-content-center align-items-center">
      <div>{"404 Not Found"}</div>
    </div>
  );
}

export default NotFound;
