import React from "react";
import { Card as BSCard, CardProps as BSCardProps } from "react-bootstrap";

export interface CardProps extends BSCardProps {
  children?: React.ReactNode;
  className?: string;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  imgSrc?: string;
  onClick?: (e: React.MouseEvent) => unknown;
  testIdPrefix?: string;
  title?: React.ReactNode;
}

function Card({
  children,
  className,
  footer,
  header,
  imgSrc,
  onClick,
  testIdPrefix = "",
  title,
  ...cardProps
}: CardProps) {
  return (
    <BSCard
      className={className}
      data-testid={`${testIdPrefix}Card`}
      onClick={onClick}
      {...cardProps}
    >
      {imgSrc && (
        <BSCard.Img
          data-testid={`${testIdPrefix}CardImg`}
          variant="top"
          src={imgSrc}
        />
      )}
      {header && (
        <BSCard.Header data-testid={`${testIdPrefix}CardHeader`}>
          {header}
        </BSCard.Header>
      )}
      <BSCard.Body data-testid={`${testIdPrefix}CardBody`}>
        {title && (
          <BSCard.Title
            className="mb-3"
            data-testid={`${testIdPrefix}CardTitle`}
          >
            {title}
          </BSCard.Title>
        )}
        <span data-testid={`${testIdPrefix}CardContent`}>{children}</span>
      </BSCard.Body>
      {footer && (
        <BSCard.Footer data-testid={`${testIdPrefix}CardFooter`}>
          {footer}
        </BSCard.Footer>
      )}
    </BSCard>
  );
}

export default Card;

interface CardHeaderLeftRightProps {
  left: React.ReactNode;
  right: React.ReactNode;
}

export function CardHeaderLeftRight({ left, right }: CardHeaderLeftRightProps) {
  return (
    <div className="d-flex justify-content-between">
      <span>{left}</span>
      <span>{right}</span>
    </div>
  );
}
