import { useMediaQuery } from "react-responsive";
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";

const sm = 576;
const md = 768;
const lg = 992;
const xl = 1200;

// sizes
export function useIsExtraSmall() {
  return useMediaQuery({ maxWidth: sm - 1 });
}

export function useIsSmall() {
  return useMediaQuery({ minWidth: sm, maxWidth: md - 1 });
}

export function useIsMedium() {
  return useMediaQuery({ minWidth: md, maxWidth: lg - 1 });
}

export function useIsLarge() {
  return useMediaQuery({ minWidth: lg, maxWidth: xl - 1 });
}

export function useIsExtraLarge() {
  return useMediaQuery({ minWidth: xl });
}

// devices
export function useIsPortraitMobile() {
  return useIsExtraSmall();
}

export function useIsLandscapeMobile() {
  return useIsSmall();
}

export function useIsMobileOnly() {
  return isMobileOnly;
}

export function useIsMobile() {
  // both mobile and tablet
  return isMobile;
}

export function useIsTablet() {
  return isTablet;
}

export function useIsDesktop() {
  return useIsLarge();
}

export function useIsLargeDesktop() {
  return useIsExtraLarge();
}
