import React from "react";
import routes from "util/routes";
import {
  BodyWrapper,
  Footer,
  LayoutWrapper,
  NavbarWrapper,
  useIsExtraSmall,
} from "@norah/ui";
import { Nav } from "react-bootstrap";
import { UserCircleIcon } from "@norah/icons";

interface Props {
  children: React.ReactNode;
}

function LoggedInLayout({ children }: Props) {
  const xs = useIsExtraSmall();
  const [expanded, setExpanded] = React.useState<boolean>(false);

  return (
    <LayoutWrapper>
      <NavbarWrapper
        appName="Norah Health | Demo"
        brandUrl={routes.home.url()}
        collapseOnSelect={true}
        expand="sm"
        expanded={expanded}
        onToggle={() => {
          setExpanded((e) => !e);
        }}
      >
        {!xs && (
          <Nav className="ml-auto">
            <UserCircleIcon className="mr-2" />
            {"Demo User"}
          </Nav>
        )}
      </NavbarWrapper>
      <BodyWrapper>{children}</BodyWrapper>
      <Footer additionalContent={" | interested@norahhealth.com"} />
    </LayoutWrapper>
  );
}

export default LoggedInLayout;
