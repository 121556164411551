import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

type Props = HighchartsReact.Props;

function Chart(props: Props) {
  return <HighchartsReact highcharts={Highcharts} {...props} />;
}

export default Chart;
