import { VITE_ENABLE_GOOGLE_ANALYTICS } from "util/constants";

export const GOOGLE_ANALYTICS_ID = "UA-146049227-1";

const gtag = (window as any).gtag || (() => {});

export function trackPage(pathname: string, title?: string) {
  if (VITE_ENABLE_GOOGLE_ANALYTICS) {
    gtag("config", GOOGLE_ANALYTICS_ID, {
      page_title: title,
      page_path: pathname,
    });
  }
}

type EventCategory = "demo-action";

export function trackEvent(
  action: string,
  {
    category,
    label,
    value,
  }: { category?: EventCategory; label?: string; value?: number } = {}
) {
  if (VITE_ENABLE_GOOGLE_ANALYTICS) {
    gtag("event", action, {
      event_category: category,
      event_label: label,
      value,
    });
  }
}
