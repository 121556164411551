import React from "react";
import cn from "classnames";

type Props = {
  children: React.ReactNode;
  color: "white" | "black";
  glare?: boolean;
  orientation?: "portrait" | "landscape";
  type: "iphone-5" | "imac" | "ipad" | "macbook-pro";
};

function Device({
  children,
  color,
  glare,
  orientation = "portrait",
  type,
}: Props) {
  return (
    <div
      className={cn({
        [`md-${type}`]: type,
        [`md-${color}-device`]: color,
        "md-landscape": orientation === "landscape",
        "md-glare": glare,
      })}
    >
      <div className="md-body">
        <div className="md-buttons" />
        <div className="md-front-camera" />
        <div className="md-top-speaker" />
        <div className="md-screen">{children}</div>
        <button className="md-home-button" />
      </div>
    </div>
  );
}

export default Device;
