import React from "react";
import { Switch, Route } from "react-router";
import LoggedInLayout from "components/Layouts/LoggedIn";
import NotFoundLayout from "components/Layouts/NotFound";
import Dashboard from "components/Dashboard";
import Demo from "components/Demo";
import routes from "util/routes";

function LoggedInRoutes() {
  return (
    <Switch>
      <LoggedInRoute path={routes.demo.path} component={Demo} />
      <LoggedInRoute
        exact={true}
        path={routes.home.path}
        component={Dashboard}
      />
      <Route path="*">
        <NotFoundLayout />
      </Route>
    </Switch>
  );
}

export default LoggedInRoutes;

interface LoggedInRouteProps {
  component: React.ComponentType<any>;
  componentProps?: any;
  exact?: boolean;
  path?: string | string[];
}

export function LoggedInRoute(props: LoggedInRouteProps) {
  const { component: Component, componentProps, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(props) => (
        <LoggedInLayout {...props}>
          <Component {...componentProps} {...props} />
        </LoggedInLayout>
      )}
    />
  );
}
