import React from "react";
import norahIcon from "./img/norah-icon.png";
import norahIconCircle from "./img/norah-icon-circle.png";

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  circle?: boolean;
}

function NorahLogoIcon({ circle, ...rest }: Props) {
  return (
    <img
      alt="Norah Health Logo"
      data-testid="norahLogoIcon"
      src={circle ? norahIconCircle : norahIcon}
      {...rest}
    />
  );
}

export default NorahLogoIcon;
