import React from "react";
import { Link } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import { NorahLogoIcon } from "@norah/icons";
import { Alerts } from "@norah/alerts";
import { NavbarProps } from "react-bootstrap";
import cx from "classnames";

interface Props {
  children?: React.ReactNode;
  className?: string;
}

export function LayoutWrapper({ children, className }: Props) {
  return <div className={cx("c-wrapper", className)}>{children}</div>;
}

export interface NavbarWrapperProps extends NavbarProps {
  appName?: React.ReactNode;
  brandUrl: string;
  children?: React.ReactNode;
  className?: string;
}

export function NavbarWrapper({
  appName,
  brandUrl,
  children,
  className,
  ...navbarProps
}: NavbarWrapperProps) {
  return (
    <Navbar className={cx("c-header", className)} {...navbarProps}>
      <Navbar.Brand>
        <Link className="navbar-brand" to={brandUrl}>
          <NorahLogoIcon
            width="22"
            height="30"
            className="d-inline-block align-top"
          />
          {appName && <span className="ml-2 mt-3">{appName}</span>}
        </Link>
      </Navbar.Brand>
      {children}
    </Navbar>
  );
}

interface BodyWrapperProps extends Props {
  flex?: boolean;
}

export function BodyWrapper({ children, className, flex }: BodyWrapperProps) {
  return (
    <div className={cx("c-body", className)}>
      <main className={cx("main c-main", { "d-flex": flex })}>
        <Alerts />
        {children}
      </main>
    </div>
  );
}

export function SidebarWrapper({ children, className }: Props) {
  return <div className={cx("c-sidebar sidebar", className)}>{children}</div>;
}
