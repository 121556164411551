import React from "react";
import routes from "util/routes";
import { trackEvent } from "util/gtm";
import { useHistory } from "react-router-dom";
import { Card } from "@norah/ui";

function Dashboard() {
  const { push } = useHistory();
  return (
    <Card
      className="startDemoCard"
      onClick={() => {
        trackEvent("start-demo", { category: "demo-action" });
        push(routes.demo.selectPatient.url());
      }}
    >
      <p>Start Demo</p>
    </Card>
  );
}

export default Dashboard;
