import React from "react";
import LoggedInLayout from "components/Layouts/LoggedIn";
import { NotFound } from "@norah/ui";

function NotFoundLayout() {
  return (
    <LoggedInLayout>
      <NotFound />
    </LoggedInLayout>
  );
}

export default NotFoundLayout;
